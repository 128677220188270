
import { defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
    props: {
        watFocus:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            moment: moment
        }
    },
    methods:{
        open(){
            this.$router.push({name:'focusPreview', params:{
                id: this.watFocus.identifier,
                slotHash: this.watFocus.slot.slotHash
            }})
        }
    }
})
